import { computed } from "vue";
import store from "@/store";
import { locations } from "@/composables/maps/locations";
import { DeviceStatusEnums } from "@/store/modules/devices/types";

export function find_devices() {
  const { isValidFeature, getCenterOfCoordinates } = locations();

  const geojson = computed(() => {
    return store.getters["locations/geojson"];
  });

  const devicesProximity = computed(() => {
    return store.getters["devices/devicesProximity"];
  });

  const filters = computed(() => {
    return store.getters["filters/filters"];
  });

  const selectedItem = computed(() => {
    return store.getters["findDevices/selectedItem"];
  });

  const hasSelectedItem = computed(() => {
    return selectedItem.value.type && selectedItem.value.devices;
  });

  const tagOption = computed(() => {
    return store.getters["findDevices/tagOption"];
  });

  const centerOfMap = computed(() => {
    if (!geojson.value?.features) return null;
    const coordinates = geojson.value.features
      .filter(isValidFeature)
      .map((feature) => feature.geometry.coordinates[0][0]);

    return getCenterOfCoordinates(coordinates);
  });

  const mapCenter = computed(() => {
    return store.getters["locations/center"];
  });

  const logDeviceProximity = (device: any) => {
    const latitude = device.location?.latitude;
    const longitude = device.location?.longitude;
    const location = { lat: latitude, lng: longitude };
    const deviceObject = {
      serial_number: device.serial_number,
      location,
      mac: device.beacon?.mac,
    };
    console.log({ device: JSON.parse(JSON.stringify(deviceObject)) });
  };

  const addMinOffsetToCoordinate = (coordinate) => {
    return {
      lat: coordinate.lat + 0.00000000000001,
      lng: coordinate.lng + 0.00000000000001,
    };
  };

  const getCoordinateOfRoom = (location) => {
    const roomName = location.room_name;
    if (!roomName) return null;
    const jsonRoom = geojson.value.features.filter(
      (feature) =>
        feature.properties["ROOM-NAME"]?.toLowerCase() ===
        roomName.toLowerCase()
    );
    if (jsonRoom.length === 0) return null;

    return getCenterOfCoordinates(jsonRoom[0].geometry.coordinates[0]);
  };

  const getCoordinateOfUnit = (location) => {
    const unitName = location.unit_name;
    if (!unitName) return null;
    const coordinates = geojson.value.features
      .filter(
        (feature) =>
          feature.properties["UNIT"]?.toLowerCase() === unitName.toLowerCase()
      )
      .filter(isValidFeature)
      .map((feature) => feature.geometry.coordinates[0][0]);
    if (coordinates.length === 0) return null;

    return coordinates;
  };

  const resetCenterOfMapAfterSelect = (device) => {
    let lat = device.location?.latitude;
    let lng = device.location?.longitude;

    if (!lat || !lng) {
      const roomCoordinate = getCoordinateOfRoom(device.location);
      const unitCoordinates = getCoordinateOfUnit(device.location);

      if (roomCoordinate) {
        lat = roomCoordinate.lat;
        lng = roomCoordinate.lng;
      } else if (unitCoordinates) {
        const unitCoordinate = getCenterOfCoordinates(unitCoordinates);
        lat = unitCoordinate.lat;
        lng = unitCoordinate.lng;
      } else {
        if (!centerOfMap.value) {
          return;
        }
        lat = centerOfMap.value.lat;
        lng = centerOfMap.value.lng;
      }
    }

    if (mapCenter.value.lat === lat) {
      lat = addMinOffsetToCoordinate({ lat, lng }).lat;
    }

    store.commit("locations/SET_CENTER", { lat, lng });
  };

  const setDefaultFilter = async () => {
    // if (!filters.value.department) {
    //   const department = departments.value.find(
    //     (department) => department.name === "Respiratory"
    //   );
    //   if (department) {
    //     filters.value.department = department;
    //   }
    // }
    // if (!filters.value.category && filters.value.department) {
    //   await store.dispatch("devices/getCategories", {
    //     departmentId: filters.value.department.id,
    //   });
    //   const category = categories.value.find(
    //     (category) => category.name === "Ventilators"
    //   );
    //   if (category) {
    //     filters.value.category = category;
    //   }
    // }
    if (
      !filters.value.deviceStatuses ||
      filters.value.deviceStatuses === DeviceStatusEnums.UNAVAILABLE
    ) {
      filters.value.deviceStatuses = DeviceStatusEnums.AVAILABLE;
    }
    store.commit("filters/SET_FILTERS", filters.value);
  };

  const plotRoomDevices = computed(() => {
    if (!geojson.value?.features) return null;
    const roomDevices = devicesProximity.value.filter(
      (device) => device.location?.room_name
    );

    const result = {};

    for (const roomDevice of roomDevices) {
      const roomName = roomDevice.location.room_name;

      const coordinate = getCoordinateOfRoom(roomDevice.location);
      if (!coordinate) continue;

      if (!result[roomName]) {
        result[roomName] = {
          count: 1,
          center: coordinate,
          devices: [roomDevice],
        };
      } else {
        result[roomName].count++;
        result[roomName].devices.push(roomDevice);
      }
    }
    return result;
  });

  const plotUnitDevices = computed(() => {
    if (!geojson.value?.features) return null;
    const unitDevices = devicesProximity.value.filter(
      (device) => !device.location?.room_name && device.location?.unit_name
    );

    const result = {};

    for (const unitDevice of unitDevices) {
      const unitName = unitDevice.location.unit_name;

      const coordinates = getCoordinateOfUnit(unitDevice.location);
      if (!coordinates) continue;

      if (!result[unitName]) {
        const coordinate = getCenterOfCoordinates(coordinates);
        result[unitName] = {
          count: coordinates.length,
          center: coordinate,
          devices: [unitDevice],
        };
      } else {
        result[unitName].count++;
        result[unitName].devices.push(unitDevice);
      }
    }

    return result;
  });

  const plotFloorDevices = computed(() => {
    if (!geojson.value?.features) return null;
    const coordinates = geojson.value.features
      .filter(isValidFeature)
      .map((feature) => feature.geometry.coordinates[0][0]);

    const center = getCenterOfCoordinates(coordinates);
    const centerKey = `${center.lat},${center.lat}`;

    return devicesProximity.value.reduce((acc, device) => {
      if (device.location?.room_name || device.location?.unit_name) {
        return acc;
      }

      if (tagOption.value === "tagged" && !device.beacon) {
        return acc;
      }

      if (tagOption.value === "untagged" && device.beacon) {
        return acc;
      }
      if (
        device.location &&
        typeof device.location.latitude === "number" &&
        typeof device.location.longitude === "number"
      ) {
        const { latitude, longitude } = device.location;
        const key = `${latitude},${longitude}`;

        if (acc[key]) {
          acc[key].count += 1;
          acc[key].devices.push(device);
          const location_source = acc[key].location_source;
          if (!location_source) {
            acc[key].location_source = device.beacon?.location_source;
          }
        } else {
          acc[key] = {
            center: { lat: latitude, lng: longitude },
            count: 1,
            location_source: device.beacon?.location_source,
            devices: [device],
          };
        }
      } else {
        if (acc[centerKey]) {
          acc[centerKey].count += 1;
          acc[centerKey].devices.push(device);
        } else {
          acc[centerKey] = {
            center: { lat: center.lat, lng: center.lng },
            count: 1,
            devices: [device],
          };
        }
      }
      return acc;
    }, {});
  });

  return {
    hasSelectedItem,
    setDefaultFilter,
    logDeviceProximity,
    addMinOffsetToCoordinate,
    centerOfMap,
    resetCenterOfMapAfterSelect,
    roomDevices: plotRoomDevices,
    unitDevices: plotUnitDevices,
    floorDevices: plotFloorDevices,
  };
}
