<template>
  <div class="modal__table__row">
    <h5 class="modal__table__row__title">{{ title }}</h5>
    <div class="modal__table__row__body">
      <slot v-if="hasSlot" />
      <h5 v-else class="modal__table__row__value">
        {{ isNumber(value) ? value : (value || "N/A") }}
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemRow",
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number],
      required: false,
    },
    hasSlot: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props, { attrs }) {
    // const hasValueProp = "value" in props;
    // const hasValueProp = props.value !== Symbol.for("unique-sentinel"); // Compare with the sentinel

    const hasValueProp = props.value !== undefined;
    const isNumber = (str) => {
      return /^\d+$/.test(str);
    };

    return {
      isNumber,
      hasValueProp,
    };
  },
};
</script>

<style scoped></style>
