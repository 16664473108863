import { computed } from "vue";
import store from "@/store";
import * as geolib from "geolib";

export function locations() {
  const highestFloor = 8;
  const lowestFloor = 0;

  const geojson = computed(() => {
    return store.getters["locations/geojson"];
  });

  const mapStyles = [
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }],
    },
  ];

  const isValidFeature = (feature) => {
    const coordinates = feature?.geometry?.coordinates;

    return (
      coordinates &&
      coordinates.length > 0 &&
      coordinates[0].length > 0 &&
      coordinates[0][0].length > 0
    );
  };

  const floorRanges = computed(() => {
    const floors: number[] = [];
    for (let i = highestFloor; i >= lowestFloor; i--) {
      floors.push(i);
    }
    return floors;
  });

  const transformCoordinates = (coordinates) => {
    return coordinates.map((coordinate) => {
      return {
        lat: coordinate[1],
        lng: coordinate[0],
      };
    });
  };

  const features = computed(() => {
    if (!geojson.value?.features) return [];
    // console.log(geojson.value?.features);
    return geojson.value.features
      .filter((feature) => {
        const coordinates = feature?.geometry?.coordinates;
        return (
          coordinates &&
          coordinates.length > 0 &&
          coordinates[0].length > 0 &&
          coordinates[0][0].length > 0
        );
      })
      .map((feature) => {
        const coordinates = feature.geometry.coordinates;
        const transformedCoordinates = transformCoordinates(coordinates[0][0]);

        return {
          paths: transformedCoordinates,
          properties: feature.properties,
          jsonId: feature.jsonId,
          center: getCoordinatesCenter(transformedCoordinates),
          strokeColor: feature.strokeColor ? feature.strokeColor : "#737879",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#D5EAE5",
          fillOpacity: 0.35,
          zIndex: 3,
        };
      });
  });

  const offsetLatCoordinate = (latitude, longitude, distanceInMeters) => {
    const earthRadius = 6371000; // Approximate radius of the Earth in meters
    const metersToDegrees = 1 / ((2 * Math.PI * earthRadius) / 360); // Conversion factor from meters to degrees
    const distanceInDegrees = distanceInMeters * metersToDegrees;
    const newLatitude = latitude + distanceInDegrees;
    return { lat: newLatitude, lng: longitude, metersToDegrees };
  };

  const offsetLngCoordinate = (latitude, longitude, distanceInMeters) => {
    const earthRadius = 6371000; // Approximate radius of the Earth in meters
    const metersToDegrees = 1 / ((2 * Math.PI * earthRadius) / 360); // Conversion factor from meters to degrees
    const distanceInDegrees = distanceInMeters * metersToDegrees;
    const newLongitude = longitude + distanceInDegrees;
    return { lat: latitude, lng: newLongitude, metersToDegrees };
  };

  const offsetCoordinates = (
    latitude,
    longitude,
    latOffsetMeters,
    lngOffsetMeters
  ) => {
    // First offset the latitude
    const latOffset = offsetLatCoordinate(latitude, longitude, latOffsetMeters);

    // Then offset the longitude using the new latitude
    const finalOffset = offsetLngCoordinate(
      latOffset.lat,
      latOffset.lng,
      lngOffsetMeters
    );

    return {
      lat: finalOffset.lat,
      lng: finalOffset.lng,
      metersToDegrees: finalOffset.metersToDegrees,
    };
  };

  const getCenterOfCoordinates = (coordinates: any[]) => {
    const coordinateRes: any = [];
    for (const coordinate of coordinates) {
      for (const coord of coordinate) {
        coordinateRes.push({
          latitude: coord[1],
          longitude: coord[0],
        });
      }
    }

    const centered = geolib.getCenterOfBounds(coordinateRes);
    return {
      lat: centered.latitude,
      lng: centered.longitude,
    };
  };

  const getCoordinatesCenter = (coordinates, offset = -0.8) => {
    if (!coordinates || coordinates.length < 3) {
      return null;
    }
    let centerLat = 0;
    let centerLng = 0;
    for (let i = 0; i < coordinates.length; i++) {
      centerLat += coordinates[i].lat;
      centerLng += coordinates[i].lng;
    }
    centerLat /= coordinates.length;
    centerLng /= coordinates.length;

    return offsetLatCoordinate(centerLat, centerLng, offset);
  };

  return {
    mapStyles,
    isValidFeature,
    transformCoordinates,
    floorRanges,
    features,
    getCenterOfCoordinates,
    offsetLatCoordinate,
    offsetCoordinates,
    offsetLngCoordinate,
    getCoordinatesCenter,
  };
}
