import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { FindDevicesState } from "@/store/modules/find-devices/types";
import AxiosClient from "@/services/api";
const BASE_URL = "/v1";

export const actions: ActionTree<FindDevicesState, RootState> = {
  getDevicesList(
    { commit, rootState },
    {
      pagination = { limit: 25, offset: 0 },
      set = true,
      order_by_last_seen = null,
    } = {}
  ) {
    if (!pagination) pagination = { limit: 25, offset: 0 };
    const filter = this.getters["filters/filters"];

    const url = `${BASE_URL}/devices/proximity`;
    const root: any = rootState;

    const floorNumber = root.locations.floor.floor_number;
    const latitude = 40.6397662;
    const longitude = -73.999;

    const params = {
      floor_number: floorNumber,
      latitude,
      longitude,
      ...pagination,
    };

    if (filter.deviceStatuses) {
      params["status"] = filter.deviceStatuses;
    }
    if (filter.locationUnit) {
      params["unit_id"] = filter.locationUnit.id;
    }

    if (order_by_last_seen) {
      params["order_by_last_seen"] = true;
    }

    if (filter.category && filter.category.id) {
      params["category_id"] = filter.category.id;
    } else if (filter.department && filter.department.id) {
      params["department_id"] = filter.department.id;
    }

    return AxiosClient.get(url, {
      params,
    })
      .then((res) => {
        if (set) {
          // commit("SET_DEVICES_LIST", Object.assign({}, res.data));
          // commit("SET_DEVICES_DUMP_LIST", Object.assign({}, res.data));
        }
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  async getAllDevicesList({ commit, dispatch }) {
    const limit = 1500;

    const res = await dispatch("getDevicesList", {
      pagination: {
        offset: 0,
        limit,
      },
      order_by_last_seen: true,
      set: false,
    });
    const devices = res.data;
    const total = res.total_elements;
    // const totalNumPage = Math.ceil(total / limit);

    // for (let i = 1; i < totalNumPage; i++) {
    //   const res2 = await dispatch("getDevicesList", {
    //     pagination: {
    //       offset: i * limit,
    //       limit,
    //     },
    //     set: false,
    //   });
    //
    //   devices = [...devices, ...res2.data];
    // }

    commit(
      "SET_DEVICES_LIST",
      Object.assign({}, { data: devices, total_elements: total })
    );
    commit(
      "SET_DEVICES_DUMP_LIST",
      Object.assign({}, { data: devices, total_elements: total })
    );
  },

  setModal({ commit }, { modal, show }): void {
    if (show) {
      commit("CLOSE_ALL_MODALS");
    } else {
      commit("SET_CURRENT_DEVICE", null);
    }
    commit("SET_MODAL", { modal, show });
  },
  assignToClinician(_, data) {
    return AxiosClient.get(
      `${BASE_URL}/devices/${data.deviceId}/assign/borrower`
    )
      .then((res) => {
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
  assignToPatient(_, data) {
    return AxiosClient.get(
      `${BASE_URL}/devices/${data.deviceId}/assign/patient`
    )
      .then((res) => {
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
};
